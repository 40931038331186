/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { getTasksListsForUser, getTasksListForUser, updateTask, getAccounts, insertTask, createTasklist, deleteTasklist, deleteTask, updateAccount, disconnectAccount } from "@/api";
import { IAccount } from "@/types";
import { tasks_v1 } from "@googleapis/tasks";
import { useMutation, useQueries, useQueryClient, useQuery } from "@tanstack/react-query";
// import groupBy from "object.groupby";

// const setDiff = (a: Set<string>, b: Set<string>) => new Set([...a].filter((x) => !b.has(x)));


export const useTitleMap = () => {
  const queryClient = useQueryClient();

  const myMap: Record<string, string> = {};
  queryClient.getQueriesData({ queryKey: ["tasklists"]}).forEach(([queryKey, value]) => {
    if (queryKey[1].userId) {
      value?.forEach(val => myMap[val.id] = val.title);
    }
    return null;
  })
  return myMap;
}

export const useAccounts = () => useQuery({
  queryKey: ["accounts"],
  queryFn: getAccounts,
  retry: false,
})

export const useDisconnectAccount = () => {
  const queryClient = useQueryClient();
  const mutationKey = ["accounts"];

  return useMutation({
    mutationFn: async (variables: { account: IAccount }) =>
      disconnectAccount(variables.account),
    onMutate: async ({ account }) => {
      await queryClient.cancelQueries({ queryKey: mutationKey });
      const previousAccounts = queryClient.getQueryData(mutationKey);

      queryClient.setQueryData(mutationKey, (old: Record<string, IAccount>) => {
        const newAccounts = { ...old };
        delete newAccounts[account.id];
        return newAccounts;
      });

      return { previousAccounts };
    },
    onError: (err, _, context) => {
      console.error('Error disconnecting account:', err);
      queryClient.setQueryData(mutationKey, context.previousAccounts);
    },
    onSettled: async (_, error) => {
      if (error) {
        throw new Error(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: mutationKey });
      }
    },
  });
};

export const useUpdateAccount = () => {
  const queryClient = useQueryClient();
  const mutationKey = ["accounts"];

  return useMutation({
    mutationFn: async (variables: { account: IAccount; updatedFields: Partial<IAccount> }) =>
      updateAccount(variables.account, variables.updatedFields),
    onMutate: async ({ account, updatedFields }) => {
      await queryClient.cancelQueries({ queryKey: mutationKey });
      const previousAccounts = queryClient.getQueryData(mutationKey);

      queryClient.setQueryData(mutationKey, (old: Record<string, IAccount>) => ({
        ...old,
        [account.id]: { ...old[account.id], ...updatedFields }
      }));

      return { previousAccounts };
    },
    onError: (err, _, context) => {
      console.error('Error updating account:', err);
      queryClient.setQueryData(mutationKey, context.previousAccounts);
    },
    onSettled: async (_, error) => {
      if (error) {
        throw new Error(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: mutationKey });
      }
    },
  });
};

export const useTasklists = (accounts: { [id: string]: IAccount } | undefined) =>
  useQueries({
    queries: accounts && Object.keys(accounts).length
      ? Object.entries(accounts).map(([id, account]) => ({
          queryKey: ["tasklists", { userId: id }],
          queryFn: () => getTasksListsForUser(account),
        }))
      : [],
  });

export const useTaskList = (account: IAccount, listId: string) => useQuery({
  queryKey: ["tasklist", { userId: account.id, tasklistId: listId }],
  queryFn: () => getTasksListForUser(account, listId),
  refetchOnWindowFocus: true,
});

export const useAllTaskListsForUser = (accounts: { [id: string]: IAccount } | undefined) => {
  const tasklistsQueries = useTasklists(accounts);
  const allListsQueryResults = useQueries({
    queries: accounts && tasklistsQueries.length ? tasklistsQueries.filter(q => !q.isLoading).map(q => q.data).flat().map((list) => ({
      queryKey: ["tasklist", { userId: list?.accountId, tasklistId: list?.id }],
      queryFn: () => getTasksListForUser(accounts[list?.accountId || ""], list?.id || "")
    })) : [],
    combine: (results) => {
      const obj: Record<string, object> = Object.keys(accounts || {}).reduce((prev, curr) => {
        return {
          ...prev,
          [curr]: {}
        }
      }, {});
      results.forEach(res => {
        if (!res.isLoading) {
          if (res.data?.length) {
            const accountId = res.data[0]?.accountId;
            const listId = res.data[0]?.tasklistId;
            obj[accountId][listId] = res?.data;
          }
        }
      })
      return obj;
    }
  })

  return allListsQueryResults;
};

export const useUpdateTask = (account: IAccount, tasklist: string) => {
  const queryClient = useQueryClient();
  const mutationKey = ["tasklist", { userId: account.id, tasklistId: tasklist }];

  return useMutation({
    mutationFn: async (variables: { task: string; updatedFields: Partial<tasks_v1.Schema$Task> }) =>
      updateTask(account, tasklist, variables.task, variables.updatedFields),
    onSettled: async (_, error) => {
      if (error) console.error(error);
      else {
        await queryClient.invalidateQueries({ queryKey: mutationKey });
      }
    },
    onMutate: async ({ task: newTaskId, updatedFields }) => {
      await queryClient.cancelQueries({ queryKey: mutationKey })
      const previousList = queryClient.getQueryData(mutationKey);
      queryClient.setQueryData(mutationKey, (old) => old.map((task) => {
        const updatedTask = task.id === newTaskId ? { ...task, ...updatedFields } : task;
        if (task.id === newTaskId) console.log('updated task from mutation', updatedTask)
        return task.id !== newTaskId ? task : updatedTask;
      } ))
      return { previousList }
    },
    onError: (err, newList, context) => {
      console.log('err', err)
      queryClient.setQueryData(mutationKey, context.previousList)
    },
  });
};

export const useCompleteTask = (account: IAccount, tasklist: string) => {
  const queryClient = useQueryClient();
  const mutationKey = ["tasklist", { userId: account.id, tasklistId: tasklist }];

  return useMutation({
    mutationFn: async (variables: { task: string; updatedFields: Partial<tasks_v1.Schema$Task> }) =>
      updateTask(account, tasklist, variables.task, variables.updatedFields),
    onSettled: async (_, error) => {
      if (error) console.error(error);
      else {
        await queryClient.invalidateQueries({ queryKey: mutationKey });
      }
    },
    onMutate: async ({ task: newTaskId, updatedFields }) => {
      await queryClient.cancelQueries({ queryKey: mutationKey })
      const previousList = queryClient.getQueryData(mutationKey);
      queryClient.setQueryData(mutationKey, (old) => old.map((task) => task.id === newTaskId ? { ...task, ...updatedFields } : task))
      return { previousList }
    },
    onError: (err, newList, context) => {
      console.log('err', err)
      queryClient.setQueryData(mutationKey, context.previousList)
    },
  });
};

export const useInsertTask = (account: IAccount, tasklist: string) => {
  const queryClient = useQueryClient();
  const mutationKey = ["tasklist", { userId: account.id, tasklistId: tasklist }];

  return useMutation({
    mutationFn: async (variables: { tasklist: string; task: Partial<tasks_v1.Schema$Task> }) =>
      insertTask(account, tasklist, variables.task),
    onMutate: async ({ tasklist, task }) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      const queryKey = ["tasklist", { userId: account.id, tasklistId: tasklist }];
      await queryClient.cancelQueries({ queryKey })
  
      // Snapshot the previous value
      const previousList = queryClient.getQueryData(queryKey)
  
      // Optimistically update to the new value
      queryClient.setQueryData(queryKey, (old) => [ { tasklistId: tasklist, ...task, status: "needsAction" }, ...old])
  
      // Return a context object with the snapshotted value
      return { previousList }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newList, context) => {
      console.log('err', err)
      console.log('newList debug', newList);
      const queryKey = ["tasklist", { userId: account.id, tasklistId: tasklist }];
      queryClient.setQueryData(queryKey, context.previousList)
    },
    onSettled: async (_, error) => {
      if (error) {
        console.error(error);
        throw new Error(error);
      }
      else {
        return await queryClient.invalidateQueries({ queryKey: mutationKey });
      }
    },
  });
};

export const useInsertTasklist = (account: IAccount) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["tasklists", { userId: account.id }],
    mutationFn: async (variables: { account: IAccount; title: string }) =>
      createTasklist(variables.account, variables.title),
    onMutate: async () => {
  
      // Snapshot the previous value
      // const previousList = queryClient.getQueryData(queryKey)
  
      // // Optimistically update to the new value
      // queryClient.setQueryData(queryKey, (old) => [ { tasklistId: tasklist, ...task, status: "needsAction" }, ...old])
  
      // // Return a context object with the snapshotted value
      // return { previousList }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newList, context) => {
      console.log('err', err)
      console.log('newList debug', newList);
      const queryKey = ["tasklist", { userId: account.id, tasklistId: tasklist }];
      queryClient.setQueryData(queryKey, context.previousList)
    },
    onSettled: async (_, error, variables) => {
      if (error) {
        console.error(error);
        throw new Error(error);
      }
      else {
        return await queryClient.invalidateQueries({ queryKey: ["tasklists", { userId: variables.account.id }] });
      }
    },
  });
};

export const useDeleteTasklist = (account: IAccount) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["tasklists", { userId: account.id }],
    mutationFn: async (variables: { account: IAccount; tasklistId: string }) =>
      deleteTasklist(variables.account, variables.tasklistId),
    onMutate: async () => {
  
      // Snapshot the previous value
      // const previousList = queryClient.getQueryData(queryKey)
  
      // // Optimistically update to the new value
      // queryClient.setQueryData(queryKey, (old) => [ { tasklistId: tasklist, ...task, status: "needsAction" }, ...old])
  
      // // Return a context object with the snapshotted value
      // return { previousList }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err) => {
      console.log('err', err)
    },
    onSettled: async (_, error, variables) => {
      if (error) {
        console.error(error);
        throw new Error(error);
      }
      else {
        return await queryClient.invalidateQueries({ queryKey: ["tasklists", { userId: variables.account.id }] });
      }
    },
  });
};

export const useDeleteTask = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (variables: { account: IAccount; tasklist: string; task: string }) =>
      deleteTask(variables.account, variables.tasklist, variables.task),
    onMutate: async ({ account, tasklist, task }) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      const queryKey = ["tasklist", { userId: account.id, tasklistId: tasklist }];
      await queryClient.cancelQueries({ queryKey })
  
      // Snapshot the previous value
      const previousList = queryClient.getQueryData(queryKey)
  
      // Optimistically update to the new value
      queryClient.setQueryData(queryKey, (old) => old.filter(t => t.id !== task))
  
      // Return a context object with the snapshotted value
      return { previousList }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newList, context) => {
      const queryKey = ["tasklist", { userId: account.id, tasklistId: tasklist }];
      queryClient.setQueryData(queryKey, context.previousList)
    },
    onSettled: async (_, error) => {
      if (error) {
        console.error(error);
        throw new Error(error);
      }
      else {
        return await queryClient.invalidateQueries({ queryKey });
      }
    },
  });
};

export const useMoveTask = () => {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async (variables: {
      sourceAccount: IAccount;
      sourceTasklist: string;
      targetAccount: IAccount;
      targetTasklist: string;
      task: tasks_v1.Schema$Task;
    }) => {
      // First create the task in the target list
      const newTask = await insertTask(variables.targetAccount, variables.targetTasklist, {
        title: variables.task.title,
        notes: variables.task.notes,
        due: variables.task.due,
        status: variables.task.status,
      });
      
      // Then delete from the source list
      await deleteTask(variables.sourceAccount, variables.sourceTasklist, variables.task.id);
      
      return newTask;
    },
    onMutate: async ({ sourceAccount, sourceTasklist, targetAccount, targetTasklist, task }) => {
      const sourceQueryKey = ["tasklist", { userId: sourceAccount.id, tasklistId: sourceTasklist }];
      const targetQueryKey = ["tasklist", { userId: targetAccount.id, tasklistId: targetTasklist }];
      
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey: sourceQueryKey });
      await queryClient.cancelQueries({ queryKey: targetQueryKey });
      
      // Snapshot the previous values
      const previousSourceList = queryClient.getQueryData(sourceQueryKey);
      const previousTargetList = queryClient.getQueryData(targetQueryKey);
      
      // Optimistically update both lists
      queryClient.setQueryData(sourceQueryKey, (old) => 
        old.filter(t => t.id !== task.id)
      );
      
      queryClient.setQueryData(targetQueryKey, (old) => [
        { ...task, tasklistId: targetTasklist },
        ...old
      ]);
      
      return { previousSourceList, previousTargetList };
    },
    onError: (err, variables, context) => {
      // Revert both lists on error
      const sourceQueryKey = ["tasklist", { userId: variables.sourceAccount.id, tasklistId: variables.sourceTasklist }];
      const targetQueryKey = ["tasklist", { userId: variables.targetAccount.id, tasklistId: variables.targetTasklist }];
      
      queryClient.setQueryData(sourceQueryKey, context.previousSourceList);
      queryClient.setQueryData(targetQueryKey, context.previousTargetList);
      
      console.error('Error moving task:', err);
    },
    onSettled: async (_, error, variables) => {
      if (error) {
        throw new Error(error);
      } else {
        // Invalidate both affected lists
        await queryClient.invalidateQueries({
          queryKey: ["tasklist", { userId: variables.sourceAccount.id, tasklistId: variables.sourceTasklist }]
        });
        await queryClient.invalidateQueries({
          queryKey: ["tasklist", { userId: variables.targetAccount.id, tasklistId: variables.targetTasklist }]
        });
      }
    },
  });
};