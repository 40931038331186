import { useState, useEffect } from 'react';
import { DropResult } from 'react-beautiful-dnd';

interface Task {
  id: string;
  position: number;
  due?: string;
  created: string;
  title: string;
}

type SortType = 'position' | 'dueDate' | 'creationDate' | 'alphabetical';

const sortFunctions: Record<SortType, (a: Task, b: Task) => number> = {
  position: (a, b) => a.position - b.position,
  dueDate: (a, b) => (a.due && b.due ? new Date(a.due).getTime() - new Date(b.due).getTime() : 0),
  creationDate: (a, b) => new Date(a.created).getTime() - new Date(b.created).getTime(),
  alphabetical: (a, b) => a.title.localeCompare(b.title),
};

export const useSortableList = (initialItems: Task[], sortType: SortType) => {
  const [items, setItems] = useState<Task[]>(initialItems);

  useEffect(() => {
    const sortedItems = [...initialItems].sort(sortFunctions[sortType]);
    setItems(sortedItems);
  }, [initialItems, sortType]);

  const onDragEnd = (result: DropResult) => {
    console.log("onDragEnd result", result);
    if (!result.destination) return;

    const newItems = Array.from(items);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);
    console.log("newItems", newItems);

    setItems(newItems);

    // Here you would typically call an API to update the backend positions
    // updateBackendPositions(newItems);
  };

  return { items, setItems, onDragEnd };
};