import { create } from 'zustand'

interface IAccount {
  id: string,
  name: string,
  email: string,
  picture: string,
  accessToken?: string,
}

interface AccountState {
  accounts: {
    [id: string]: IAccount
  },
  addAccount: (account: IAccount) => void,
  reset: () => void,
}

export const useAccountsStore = create<AccountState>()((set) => ({
  accounts: {},
  addAccount: (account) => set((state) => ({ accounts: { ...state.accounts, [account.id]: account } })),
  reset: () => set(() => ({ accounts: {} }))
}))

export default useAccountsStore;