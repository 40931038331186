import { Routes, Route } from "react-router-dom";

import PrivateRoute from "./routes/PrivateRoute";
import Home from "./components/Home";
import AuthPage from "./components/AuthPage";
import Policy from "./components/Policy";
import { ThemeProvider } from "./ThemeProvider";
import { useEffect, useState } from "react";
import { TooltipProvider } from "./components/ui/tooltip";

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Adjust the width as per your requirement

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust the width as per your requirement
    };

    window.addEventListener("resize", handleResize);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (isMobile) {
    return <div className="flex flex-col justify-center items-center h-screen">
      <p>Mobile view not supported yet.</p> 
      <p>Please visit this page on a desktop or tablet.</p>
    </div>;
  }

  return (
    <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
      <TooltipProvider delayDuration={200}>
    <Routes>
      <Route path="/login" element={<AuthPage />} />

      <Route element={<PrivateRoute />}>
        <Route path="/" element={<Home />}></Route>
      </Route>

      <Route path="/privacy" element={<Policy />} />

      <Route path="*" element={<div>404</div>} />
    </Routes>
      </TooltipProvider>
    </ThemeProvider>
  );
}

export default App;
