import { IAccount } from "@/types";

export const convertDateToISOString = (jsDate: Date): string => {
  // Get the local date components
  const year = jsDate.getFullYear();
  const month = jsDate.getMonth() + 1; // JavaScript months are 0-based
  const day = jsDate.getDate();

  // Create a UTC date string with the local date components
  const utcDate = new Date(Date.UTC(year, month - 1, day));

  // Return the ISO string, ensuring to set time to 00:00:00.000Z
  return utcDate.toISOString().split('T')[0] + 'T00:00:00.000Z';
};

export const getAccountDisplayName = (account: IAccount, maxLength: number | null = null) => {
  const displayName = account.nickname || account.name;
  if (!maxLength) return displayName;
  return displayName.length > maxLength ? truncateString(displayName, maxLength) : displayName;
}

export const truncateString = (str: string, maxLength: number) => {
  return str.length > maxLength ? `${str.substring(0, maxLength)}...` : str;
}