/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useGoogleLogin } from "@react-oauth/google";
import { Navigate, useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import useUserStore from "@/stores/useUserStore";
import useAccountsStore from "@/stores/useAccountsStore";
import logo from "@/assets/new-logo-2.png";
import { Button } from "@/components/ui/button";
import { login } from "@/api"
import { LogosGoogleIcon } from "./GoogleIcon";

import "./AuthPage.css";
import { useState } from "react";
import LoadingPage from "./LoadingPage";
import { useAccounts } from "@/hooks";

const AuthPage = () => {
  const navigate = useNavigate();
  const setUser = useUserStore((state) => state.setUser);
  const addAccount = useAccountsStore((state) => state.addAccount);
  const { data: accounts, isLoading: accountsLoading } = useAccounts();
  const [loading, setLoading] = useState(false);

  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        setLoading(true);
        const user = await login(codeResponse.code)
        Sentry.addBreadcrumb({
          category: "auth",
          message: "login success",
          level: "info",
        });
        setUser(user);
        addAccount(user);
        Sentry.captureMessage("set cookie navigating home", {
          level: "info",
          extra: {
            cookie: document.cookie,
          },
        });
        navigate("/");
        setLoading(false);
      } catch (error) {
        console.error(error);
        Sentry.captureException(error);
        setLoading(false);
      }
    },
    onError: (codeResponse) => {
      console.error(codeResponse);
      setLoading(false);
    },
    flow: "auth-code",
    ux_mode: "popup",
    select_account: true,
    scope: ["https://www.googleapis.com/auth/tasks"],
  });

  if (loading || accountsLoading) return <LoadingPage />
  if (accounts && Object.keys(accounts || {}).length > 0) return <Navigate to="/" />

  return (
    <div className="h-screen flex flex-col w-screen bg items-center justify-center">

      <div className="flex flex-col justify-end flex-1">

        <div className="flex gap-2 items-center mb-8">
          <img id="logo" src={logo} style={{ height: "1.5rem" }}></img>
          <h1 className="text-foreground" style={{ fontFamily: "Open Sans", fontWeight: "500", fontSize: "1.5rem" }}>
            Task Fusion
          </h1>
        </div>
      </div>

        <div className="flex justify-center items-center">
        <Button className="flex gap-4" size="lg" onClick={googleLogin}>
          <LogosGoogleIcon /><span>Login with Google</span>
        </Button>
      </div>

      <div
          className="flex-1 flex flex-col justify-end text-foreground"
          onClick={() => navigate("/privacy")}
        style={{ textAlign: "center", marginBottom: "1rem", cursor: "pointer" }}
      >
        Privacy Policy
      </div>
    </div>
  );
};

export default AuthPage;
