/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useInsertTask, useUpdateTask } from "@/hooks";
import { useState, useEffect, useRef } from "react";
import { Button } from "./ui/button";
import TextareaAutosize from 'react-textarea-autosize';
import { convertDateToISOString } from "@/helpers";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import { cn } from "@/lib/utils";
import { CalendarIcon } from "lucide-react";
import { Calendar } from "./ui/calendar";
import { tasks_v1 } from "@googleapis/tasks";

export function DatePicker({ date, setDate, handleFocus, handleBlur, onOk }) {

  const handleToggle = (open) => {
    if (open) {
      handleFocus();
    } else {
      handleBlur();
    }
  }

  return (
    <Popover onOpenChange={handleToggle}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          size="sm"
          className={cn(
            "rounded-full justify-start text-left font-normal text-xs hover:bg-accent my-2 px-4 w-fit bg-transparent",
            !date && "text-muted-foreground"
          )}
          onClick={() => { 
            setTimeout(() => {
              handleFocus();
            }, 100); 
          }}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? date.toLocaleString().split(",")[0] : <span>Date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          className="dark:bg-muted"
          mode="single"
          selected={date}
          onSelect={(d) => {
            setDate(d)
          }}
          initialFocus
        />
        <div className="flex justify-end w-full p-4 pt-0 dark:bg-muted">
        <Button  size="sm" onClick={onOk}>OK</Button>
        </div>
      </PopoverContent>
    </Popover>
  )
}

const TaskInput = ({ listId, setEditing, account, inputRef, editing, task, }) => {
  const [title, setTitle ] = useState(task?.title || "");
  const [date, setDate] = useState<Date>(task?.due ? new Date(task.due) : null);
  const [details, setDetails] = useState<string>(task?.notes || "");
  const containerRef = useRef()
  const insertTaskMutation = useInsertTask(account, listId);
  const updateTaskMutation = useUpdateTask(account, listId);
  const blurTimerRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      const len = title.length;
      inputRef.current.setSelectionRange(0, len);
    }, 10)
  }, [])

  const handleFocus = () => {
    clearTimeout(blurTimerRef.current);
    if (blurTimerRef.current) {
      clearTimeout(blurTimerRef.current);
    }
  };

  const handleBlur = () => {
    blurTimerRef.current = setTimeout(() => {
      setEditing('');
    }, 200);
  };

  const submitTask = () => {
    const currentTask: Partial<tasks_v1.Schema$Task> = {}
    if (title) currentTask.title = title.trim();
    if (date) currentTask.due = convertDateToISOString(date);
    if (details) currentTask.notes = details;

    const jsDate = new Date(currentTask.due)

    if (editing === "new") {
      insertTaskMutation.mutate({ tasklist: listId, task: currentTask })
    } else {
      updateTaskMutation.mutate({ task: task.id || "", updatedFields: { id: task.id, ...currentTask } })
    }
    setEditing("");
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      submitTask();
    }
    if (e.key === "Escape") {
      setTitle("")
      setEditing("");
    } 
  }

  return (
    <div ref={containerRef} className="flex flex-col bg-muted w-full px-4">
        <TextareaAutosize
          ref={(tag) => inputRef.current = tag}
          autoFocus
          // maxRows={3}
          className="w-full px-2 focus-visible:ring-0 focus-visible:ring-offset-0 border-transparent bg-muted text-sm py-2 outline-none resize-none"
          style={{ boxSizing: "border-box" }}
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
        />
        <TextareaAutosize value={details} onKeyDown={handleKeyDown} onFocus={handleFocus} onBlur={handleBlur} onChange={(e) => setDetails(e.target.value)} size="sm" placeholder="Details" className="focus-visible:ring-0 focus-visible:ring-offset-0 border-transparent py-0 text-xs h-4 bg-muted outline-none resize-none px-2"/>
        <DatePicker date={date} setDate={setDate} handleFocus={handleFocus} handleBlur={handleBlur} onOk={submitTask}/>
    </div>
  );
};

export default TaskInput;
