import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import { inject } from '@vercel/analytics';
import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import * as Sentry from "@sentry/react";

import { BrowserRouter } from "react-router-dom";
import "./stores/useStore.ts";

import "./index.css";

inject();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
      retry: false,
    },
  },
});

Sentry.init({
  dsn: "https://ed53b1cd8bc781eae8f17c1c15580c4e@o4508313611796480.ingest.de.sentry.io/4508313614155856",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root")!).render(
    <BrowserRouter>
      {/* <GoogleOAuthProvider clientId="630664031461-jv5sig8a3h02pp4vt8qo70vui3toeer7.apps.googleusercontent.com"> */}
      <GoogleOAuthProvider clientId="725319931235-6ock2glfv5ce5sqac0sj0cqtnjns9dvr.apps.googleusercontent.com">
        <QueryClientProvider client={queryClient}>
          <App />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </GoogleOAuthProvider>
    </BrowserRouter>
);
