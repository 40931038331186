import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useDisconnectAccount } from "@/hooks/hooks";
import { IAccount } from "@/types";
import { Button } from "./ui/button";

type DisconnectAccountDialogProps = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  account: IAccount;
}
const DisconnectAccountDialog = ({ open, onOpenChange, account }: DisconnectAccountDialogProps) => {
  const { mutate: disconnectAccount } = useDisconnectAccount();

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Disconnect Account</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          Are you sure you want to disconnect this account?
        </DialogDescription>
        <DialogFooter>
          <Button variant={"outline"} onClick={() => onOpenChange(false)}>Cancel</Button>
          <Button variant={"destructive"} onClick={() => disconnectAccount({ account })}>Disconnect</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default DisconnectAccountDialog;