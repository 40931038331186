import { IAccount } from "@/types";
import axios, { axiosPrivate } from "./axios";
import { tasks_v1 } from "@googleapis/tasks";

export const login = async (code: string) => {
  const res = await axios.post(
    "/login",
    { code },
    { withCredentials: true }
  );
  return res.data;
}

export const getAccounts = async () => {
  const response = await axiosPrivate.get("/accounts");
  return response.data as Record<string, IAccount>;
}

export const logout = async () => {
  await axiosPrivate.get("/logout")
}

export const getTasksListsForUser = async (account: IAccount) => {
  const res = await axiosPrivate.get(`/taskslists/${account?.id}`)
  const data: tasks_v1.Schema$TaskList[] = res.data;
  return data.map(d => ({ ...d, accountId: account.id }));
}

export const getTasksListForUser = async (account: IAccount, tasklistId: string) => {
  const res = await axiosPrivate.get(`/tasks/${tasklistId}/${account?.id}`)
  const data: tasks_v1.Schema$Task[] = res.data;
  return data.map(d => ({ ...d, accountId: account.id, tasklistId }))
}

export const updateTask = async (account: IAccount, tasklist: string, task: string, updatedFields: Partial<tasks_v1.Schema$Task>) => {
  const body = { uid: account.id, tasklist, task, updatedFields };
  const res = await axiosPrivate.put(`/task/${account.id}`, body) // TODO better error handling
  return res.data;
}

export const insertTask = async (account: IAccount, tasklist: string, task: Partial<tasks_v1.Schema$Task>) => {
  const body = { tasklist, task };
  const res = await axiosPrivate.post(`/task/${account.id}`, body) // TODO better error handling
  return res.data;
}

export const createTasklist = async (account: IAccount, title: string) => {
  const body = { title };
  const res = await axiosPrivate.post(`/tasklist/${account.id}`, body)
  return res.data;
}

export const deleteTasklist = async (account: IAccount, tasklistId: string) => {
  const res = await axiosPrivate.delete(`/tasklist/${tasklistId}/${account.id}`)
  return res.data;
}

export const deleteTask = async (account: IAccount, tasklistId: string, taskId: string) => {
  const body = { tasklist: tasklistId, task: taskId };
  const res = await axiosPrivate.delete(`/task/${account.id}`, { data: body })
  return res.data;
}

export const saveConsent = async (email: string, consent: boolean) => {
  const body = { email, consent };
  console.log("saving consent", body);
  const res = await axiosPrivate.post("/store-consent", body);
  return res.data;
}

export const updateAccount = async (account: IAccount, updatedFields: Partial<IAccount>) => {
  const res = await axiosPrivate.put(`/account/${account.id}`, updatedFields);
  return res.data;
}

export const disconnectAccount = async (account: IAccount) => {
  const res = await axiosPrivate.delete(`/account/${account.id}`);
  return res.data;
}