const Policy = () => {
  return (
    <div className="mx-4">
      <header className="mt-4 mb-2">
        <h1 className="text-2xl">Privacy Policy for Task Fusion</h1>
      </header>
      <section className="mb-4">
        <p>
          <strong>Effective Date:</strong> 04/20/2024
        </p>
      </section>
      <section>
        <p>
          Welcome to <b>Task Fusion!</b> Your privacy is important to us. This Privacy Policy explains how
          we collect, use, and protect your personal information when you use our app. Please read
          it carefully.
        </p>
      </section>
      <section>
        <h2 className="text-xl mt-4">Limited Use Policy</h2>
        <p>
          Task Fusion's use and transfer of information received from Google APIs to any other app will adhere to <a style={{ textDecorationLine: "underline" }} href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"> Google API Services User Data Policy</a>, including the Limited Use requirements.
        </p>
      </section>
      <section>
        <h2 className="text-xl mt-4">1. Information We Collect</h2>
        <ul>
          <li>
            <strong>Account Information:</strong> When you use our app, we collect your Google
            username, email address, and profile picture through Google OAuth to personalize and
            manage your experience.
          </li>
          <li>
            <strong>Task Data:</strong> We have access to your Google Tasks via read and write
            scopes. This includes access to your task lists and tasks for viewing, creating,
            editing, and deleting as you use our app.
          </li>
        </ul>
      </section>
      <section>
        <h2 className="text-xl mt-4">2. How We Use Your Information</h2>
        <ul>
          <li>
            To Provide Services: We use the information we collect to operate our app and provide
            you with personalized service. This includes managing your tasks across multiple Google
            accounts.
          </li>
          <li>
            Session Management: We manage your sessions in our app. Your information is used to
            maintain an active session and is not stored beyond this period.
          </li>
        </ul>
      </section>
      <section>
        <h2 className="text-xl mt-4">3. Data Storage and Retention</h2>
        <p>
          We do not store any personal data beyond the duration of your session. All personal data
          is wiped from our system when you log out of our app.
        </p>
      </section>
      <section>
        <h2 className="text-xl mt-4">4. Data Protection</h2>
        <p>
          We take reasonable measures to protect the information we collect from or about you from
          unauthorized access, use, or disclosure. However, no internet or email transmission is
          ever fully secure or error-free.
        </p>
      </section>
      <section>
        <h2 className="text-xl mt-4">5. Your Rights</h2>
        <p>
          You have certain rights regarding the personal information we hold about you. These may
          include the right to access, correct, delete, or restrict the use of your personal
          information, as well as the right to withdraw consent for its use.
        </p>
      </section>
      <section>
        <h2 className="text-xl mt-4">6. Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. We will notify you of any changes by
          posting the new Privacy Policy on our website. We encourage you to review this Privacy
          Policy periodically for any changes.
        </p>
      </section>
      <section>
        <h2 className="text-xl mt-4">7. Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us at <a href="mailto:contact@task-fusion.pro">contact@task-fusion.pro</a>.
        </p>
      </section>
    </div>
  );
};

export default Policy;
