/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useState, useRef, useEffect, useMemo } from "react";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
// import PuffLoader from "react-spinners/PuffLoader"
import PropagateLoader from "react-spinners/PropagateLoader";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button } from "./ui/button";
import TaskInput from "./TaskInput";
import TasklistItem from "./TasklistItem";
import { DotsVerticalIcon } from "@radix-ui/react-icons";
import { PlusIcon } from "lucide-react";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "./ui/accordion";
import { useDeleteTasklist, useTaskList } from "@/hooks";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useTheme } from "@/ThemeProvider";
import { useSortableList } from "@/hooks/useSortableList";
import { Badge } from "./ui/badge";

import "./ListCard.css";

const ListCard = ({ account, list, search, upcomingFilter, pastFilter }) => {
  const { data: listItems, isPending } = useTaskList(account, list.id);
  const deleteTasklistMutation = useDeleteTasklist(account);
  const [editing, setEditing] = useState("");
  const { theme } = useTheme();
  const inputRef = useRef();
  const [sortType, setSortType] = useState<"position" | "dueDate" | "creationDate" | "alphabetical">("position");

  const filteredItemsNeedsAction = useMemo(() => {
    const result = (listItems || [])
      .filter((task) => task.status === "needsAction")
      .filter((task) => (search ? task.title.toLowerCase().includes(search.toLowerCase()) : true))
      .filter((task) =>
        upcomingFilter
          ? task.due
            ? new Date(task.due).getTime() <= new Date().getTime()
            : true
          : true
      )
      .filter((task) =>
        pastFilter ? (task.due ? new Date(task.due).getTime() > new Date().getTime() : true) : true
      );
    
    return result;
  }, [listItems, upcomingFilter, pastFilter, search]);

  // Add upcoming and past filters
  const filteredItemsCompleted = useMemo(() => {
    const result = (listItems || [])
      .filter((task) => task.status === "completed")
      .filter((task) => (search ? task.title.toLowerCase().includes(search.toLowerCase()) : true))
      .filter((task) =>
        upcomingFilter
          ? task.due
            ? new Date(task.due).getTime() <= new Date().getTime()
            : true
          : true
      )
      .filter((task) =>
        pastFilter ? (task.due ? new Date(task.due).getTime() > new Date().getTime() : true) : true
      );
    
    return result;
  }, [listItems, upcomingFilter, pastFilter, search]);

  const {
    items: pendingList,
    setItems: setPendingList,
    onDragEnd: onPendingDragEnd
  } = useSortableList(filteredItemsNeedsAction, sortType);

  const {
    items: completedList,
    setItems: setCompletedList,
    onDragEnd: onCompletedDragEnd
  } = useSortableList(filteredItemsCompleted, sortType);

  const onDragEnd = (result: DropResult) => {
    if (sortType !== "position") {
      alert("Reordering is only available when using the default sort order");
      return;
    }

    if (result.type === "pending") {
      onPendingDragEnd(result);
    } else if (result.type === "completed") {
      onCompletedDragEnd(result);
    }
  };

  const sortByDueDate = () => {
    setSortType("dueDate");
  }

  const sortByCreationDate = () => {
    setSortType("creationDate");
  }

  const sortByDefault = () => {
    setSortType("position");
  }

  const reverseList = () => {
    const reversedPendingListItems = [...pendingList].reverse();
    setPendingList(reversedPendingListItems);
    const reversedCompletedListItems = [...completedList].reverse();
    setCompletedList(reversedCompletedListItems);
  }

  const onClickAdd = () => {
    setEditing("new");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 100);
  };

  if (isPending) return <div>Loading...</div>;

  const listId = list.id;

  return (
    <Card className="bg-card" style={{}}>
      <CardHeader className="py-0 pt-2 pr-2 font-medium">
        {/* <CardTitle>{`${title} (${pendingList.length})`}</CardTitle> */}
        <div className="flex justify-between items-center">
          <h2 className="text-muted-foreground text-sm inline">{list.title}</h2>
          <DropdownMenu>
            <DropdownMenuTrigger className="w w-max rounded-full relative">
              <Button className="rounded-full" size="icon" variant="ghost">
                <DotsVerticalIcon />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56">
              <DropdownMenuLabel>Sorting</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuRadioGroup value={sortType} onValueChange={setSortType}>
                <DropdownMenuRadioItem value="position">Default</DropdownMenuRadioItem>
                <DropdownMenuRadioItem value="dueDate">Due Date</DropdownMenuRadioItem>
                <DropdownMenuRadioItem value="creationDate">Creation Date</DropdownMenuRadioItem>
                <DropdownMenuRadioItem value="alphabetical">Alphabetical</DropdownMenuRadioItem>
              </DropdownMenuRadioGroup>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                className="text-red-500"
                disabled={deleteTasklistMutation.isPending}
                onClick={() => {
                  if (window.confirm("Are you sure you want to delete this list?")) {
                    deleteTasklistMutation.mutate({ account, tasklistId: listId });
                  }
                }}
              >
                Delete List
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </CardHeader>

      <CardContent className="pb-0 px-0">
        {deleteTasklistMutation.isPending ? (
          <div className="w-full flex justify-center p-4 pb-8">
            <PropagateLoader color={theme === "dark" ? "white" : "black"} size={4} loading={true} />
          </div>
        ) : (
          <>
            <Button
              className="w-full flex justify-start pl-4 gap-4 hover:bg-transparent add-button"
              size="sm"
              variant="ghost"
              onClick={onClickAdd}
            >
              <PlusIcon strokeWidth={3} color="hsl(217, 84%, 58%)" className="h-4 w-4" />
              <span className="hover:font-bold" style={{color: "hsl(217, 84%, 58%)"}}>Add</span>
            </Button>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId={listId} type="pending">
                {(provided) => (
                <ul
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                className="overflow-y-auto max-h-[350px] scrollbar-thin scrollbar-thumb-muted-foreground scrollbar-track-background">
                  {editing === "new" && (
                    <li key="currentTaskId">
                      <TaskInput
                        listId={listId}
                        editing={editing}
                        setEditing={setEditing}
                        account={account}
                        inputRef={inputRef}
                      />
                    </li>
                  )}
                  {/* {listItems?.map((task) => <li>{task.title}</li>)} */}
                  {pendingList.map((task, index) => {
                    return editing !== task.id ? (
                      <Draggable key={task.id} draggableId={task.id} index={index}>
                        {(provided) => {
                          return (
                            <li {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} key={task.id} className="z-10 flex flex-col border border-transparent hover:shadow hover:border-t-border dark:hover:border-t dark:hover:shadow dark:hover:bg-muted dark:active:bg-muted">
                              <TasklistItem
                                key={task.id + task.title}
                                task={task}
                                list={listId}
                                account={account}
                                variant="needsAction"
                                setEditing={setEditing}
                              />
                            </li>
                          )
                        }}
                      </Draggable>
                    ) : (
                        <li key={task.id}>
                          <TaskInput
                            listId={listId}
                            setEditing={setEditing}
                            account={account}
                            inputRef={inputRef}
                            task={task}
                            editing={editing}
                          />
                        </li>
                    );
                  })}
                  {provided.placeholder}
                </ul>
                )}
              </Droppable>
            </DragDropContext>
            {!completedList.length ? null : (
              <Accordion type="single" collapsible>
                <AccordionItem value="completed" className="border-none">
                  <AccordionTrigger className="px-4 text-sm">{`Completed (${completedList.length})`}</AccordionTrigger>
                  <AccordionContent>
                    <ul className="overflow-y-auto max-h-[300px] scrollbar-thin scrollbar-thumb-muted-foreground scrollbar-track-background">
                      {completedList.map((task) => (
                        <li key={task.id} className="flex flex-col border border-transparent hover:border-t-border hover:shadow dark:hover:border-t-muted-foreground dark:hover:shadow">
                          <TasklistItem
                            key={task.id}
                            task={task}
                            list={listId}
                            account={account}
                            variant="completed"
                            setEditing={setEditing}
                          />
                        </li>
                      ))}
                    </ul>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default ListCard;
