import { Dialog, DialogContent, DialogTitle } from "@/components/ui/dialog";
import { useAccounts, useMoveTask, useTasklists, useTitleMap } from "@/hooks/hooks";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { tasks_v1 } from "@googleapis/tasks";
import { IAccount } from "@/types";
import { ScrollArea, ScrollBar } from "./ui/scroll-area";
import { getAccountDisplayName } from "@/helpers";

type MoveTaskDialogProps = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  task: tasks_v1.Schema$Task;
  list: string;
  account: IAccount;
}

const MoveTaskDialog = ({ open, onOpenChange, task, list, account }: MoveTaskDialogProps) => {
  const { data: accounts } = useAccounts();
  const moveTask = useMoveTask();
  const lists = useTasklists(accounts);
  const titlesMap = useTitleMap();

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-fit">
        <DialogTitle>Move Task</DialogTitle>
        <ScrollArea className="">
          <div className="flex gap-10 bg-muted-foreground/10 px-4 py-2 rounded-md">
            {lists.map((targetListQuery) => {
              if (!targetListQuery.data) return null;
            const accountId = targetListQuery.data[0]?.accountId;
            if (!accountId) return null;
            const targetAccount = accounts?.[accountId];
            if (!targetAccount) return null;
            return <div key={targetAccount.id}>
              <div className="flex gap-2 mb-2 items-center">
                <Avatar className="h-4 w-4">
                  <AvatarImage src={targetAccount?.picture} />
                  <AvatarFallback>{`${targetAccount?.name?.charAt(0)}`}</AvatarFallback>
                </Avatar>
                <span className="min-w-max">{getAccountDisplayName(targetAccount, 15)}</span>
              </div>
              <ul className="text-sm pl-6">  
                {targetListQuery.data.map((targetList) => <li className="cursor-pointer hover:bg-accent rounded-md px-1" key={targetList.id} onClick={() => moveTask.mutate({
                  sourceAccount: account,
                  sourceTasklist: list,
                  targetAccount: targetAccount,
                  targetTasklist: targetList.id || "",
                  task: task,
                })}>{targetList.title}</li>)}
              </ul>
              </div>
            })}
          </div>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
        <div className="flex flex-col gap-1">
          <span className="text-sm text-muted-foreground">Moving task from <span className="font-bold">{titlesMap[list]}</span> to...</span>
          <span className="text-xs text-muted-foreground">Task: {task.title}</span>
          {task.notes && <span className="text-xs text-muted-foreground">Details: {task.notes}</span>}
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default MoveTaskDialog;