import { create } from 'zustand'

export interface UIState {
  checkboxes: { 
    [listId: string]: {
      [checkboxId: string]: boolean
    }
  },
  listOrder: {
    [accountId: string]: string[] // Array of list IDs in order
  },
  setCheckbox: (listId: string, checkboxId: string, isChecked: boolean) => void,
  setListOrder: (accountId: string, listIds: string[]) => void,
  moveList: (accountId: string, fromIndex: number, toIndex: number) => void
}

const useUIStore = create<UIState>()((set) => ({
  checkboxes: {},
  listOrder: {},
  setCheckbox: (listId, checkboxId, isChecked) =>
    set(state => ({
      checkboxes: {
        ...state.checkboxes,
        [listId]: {
          ...state.checkboxes[listId],
          [checkboxId]: isChecked
        }
      }
    })),
  setListOrder: (accountId, listIds) =>
    set(state => ({
      listOrder: {
        ...state.listOrder,
        [accountId]: listIds
      }
    })),
  moveList: (accountId, fromIndex, toIndex) =>
    set(state => {
      const currentOrder = state.listOrder[accountId] || [];
      const newOrder = [...currentOrder];
      const [movedItem] = newOrder.splice(fromIndex, 1);
      newOrder.splice(toIndex, 0, movedItem);
      
      return {
        listOrder: {
          ...state.listOrder,
          [accountId]: newOrder
        }
      };
    }),
}));

export default useUIStore;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
window.store = useUIStore