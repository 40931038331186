import { create } from 'zustand'

interface IUser {
  id: string,
  name: string,
  email: string,
  picture: string,
  accessToken?: string,
}

interface UserState {
  user: IUser | null,
  accessToken: IUser["accessToken"],
  setUser: (user: IUser) => void,
  setAccessToken: (accessToken: IUser["accessToken"]) => void
  accountsList: object[],
  setAccountsList: (accountsList: object[]) => void,
}

const useUserStore = create<UserState>()((set) => ({
  user: null,
  accessToken: "",
  setUser: (user) => set({ user }),
  setAccessToken: (accessToken) => set({ accessToken }),
  accountsList: [],
  setAccountsList: (accountsList) => set({ accountsList })
}))

export default useUserStore