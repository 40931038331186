import PuffLoader from "react-spinners/PuffLoader"

const LoadingPage = () => {
  return (
    <div className='flex justify-center items-center h-screen'>
      <PuffLoader loading={true}/>
    </div>
  )
}

export default LoadingPage