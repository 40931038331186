import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogTitle } from "@/components/ui/dialog";
import { useUpdateAccount } from "@/hooks/hooks";
import { IAccount } from "@/types";
import { Input } from "./ui/input";
import { useState } from "react";
import { Button } from "./ui/button";
import { Undo2Icon } from "lucide-react";

type RenameAccountDialogProps = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  account: IAccount;
}

const RenameAccountDialog = ({ open, onOpenChange, account }: RenameAccountDialogProps) => {
  const [newName, setNewName] = useState(account.nickname || account.name);
  const updateAccountMutation = useUpdateAccount();

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogTitle>Account Nickname</DialogTitle>
        <DialogDescription>
          Give a nickname to your account. This will not change your Google account name.
        </DialogDescription>
        <div className="flex items-center gap-4">
          <Input value={newName} onChange={(e) => setNewName(e.target.value)} />
          <Button className="rounded-full hover:bg-card hover:shadow" size={"icon"} variant={"ghost"} onClick={() => {
            setNewName(account.name);
          }}>
            <Undo2Icon className="h-4 w-4" />
          </Button>
        </div>
        <DialogFooter>
          <Button onClick={() => {
            updateAccountMutation.mutate({ account, updatedFields: { nickname: newName } });
            onOpenChange(false);
          }}>Rename</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default RenameAccountDialog;