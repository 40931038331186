import axios from "axios";
// import useUserStore from "@/stores/useUserStore";

const BASE_URL = import.meta.env.PROD ? "https://task-fusion-server-xv6n4.ondigitalocean.app/" : "http://localhost:8080";

export default axios.create({
  baseURL: BASE_URL,
})

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true

});

// axiosPrivate.interceptors.request.use(
//   config => {
//       if (!config.headers['Authorization']) {
//           const accessToken = useUserStore.getState().accessToken;
//           config.headers['Authorization'] = `Bearer ${accessToken}`;
//       }
//       return config;
//   }, (error) => Promise.reject(error)
// );

// axiosPrivate.interceptors.response.use(
//   response => response,
//   async (error) => {
//       const prevRequest = error?.config;
//       if (error?.response?.status !== 200 && !prevRequest?.sent) { // TODO change to check 403
//           prevRequest.sent = true;
//           const response = await axios.get("/refresh", { withCredentials: true });
//           prevRequest.headers['Authorization'] = `Bearer ${response.data.accessToken}`;
//           const user = useUserStore.getState().user;
//           if (user) {
//             useUserStore.getState().setAccessToken(response.data.accessToken)
//             useUserStore.getState().setUser({ ...user, accessToken: response.data.accessToken })
//           }
//           return axiosPrivate(prevRequest);
//       }
//       return Promise.reject(error);
//   }
// );

