/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { Navigate, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useGoogleLogin } from "@react-oauth/google";
import { MoonIcon, SearchIcon, SunIcon, Settings2Icon, ArrowRightIcon, ArrowLeftIcon, MenuIcon, ChevronsLeftIcon, ChevronsRightIcon, Rows2Icon, Columns2Icon } from "lucide-react";
import { LogOut } from "lucide-react";
import { GearIcon, InfoCircledIcon } from "@radix-ui/react-icons";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Separator } from "@/components/ui/separator";
import logo from "../assets/new-logo-2.png";

import axios from "@/api/axios";
import { logout, saveConsent } from "@/api";

import { Button } from "@/components/ui/button";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

import useAccountsStore from "@/stores/useAccountsStore";
import useUIStore from "@/stores/useUIStore";

import { useAccounts, useTitleMap, useTasklists } from "@/hooks";

import AccountSiderList from "./AccountSiderList";
import ListCard from "./ListCard";
import { PlusIcon } from "@radix-ui/react-icons";
import LoadingPage from "./LoadingPage";
import { useEffect, useRef, useState } from "react";
import { useTheme } from "@/ThemeProvider";
import { Input } from "./ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import { Tooltip, TooltipContent, TooltipTrigger } from "./ui/tooltip";
import { getAccountDisplayName } from "@/helpers";
import { DropdownMenuLabel } from "./ui/dropdown-menu";
import useLocalStorage from "@/hooks/useLocalStorage";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "@/components/ui/dialog";

type View = "list" | "grid";

const ListView = ({ order, account, listsMap, i, checkboxes, search, upcomingFilter, pastFilter }) => {
  return (
    <div className="relative px-[25%]">
      <div className="mb-4 flex gap-4">
        <Avatar className={`h-6 w-6`}>
          <AvatarImage src={account?.picture} />
          <AvatarFallback>{`${account?.name?.charAt(0)}`}</AvatarFallback>
        </Avatar>
        <span className="min-w-max">{getAccountDisplayName(account)}</span>
      </div>

      <div className="flex flex-col gap-4 pb-4">
      {order.map(
        (listId) =>
          checkboxes[account.id]?.[listId] && (
            <div
              className="flex-1 w-full"
            >
              <ListCard
                key={listId}
                list={listsMap.get(listId) || {}}
                account={account}
                search={search}
                upcomingFilter={upcomingFilter}
                  pastFilter={pastFilter}
                      />
                      </div>
                  )
            )}
          </div>
        </div>
  );
}

const GridView = ({ order, account, listsMap, i, checkboxes, search, upcomingFilter, pastFilter }) => {
  return (
    <div className="w-fit relative">
      <div className="mb-4 flex gap-4 items-center">
        <Avatar className={`h-6 w-6 ${i === 0 ? 'ml-14' : ''}`}>
          <AvatarImage src={account?.picture} />
          <AvatarFallback>{`${account?.name?.charAt(0)}`}</AvatarFallback>
        </Avatar>
        <span className="min-w-max">{getAccountDisplayName(account)}</span>
      </div>

      <div className="flex gap-4 pb-4">
      {order.map(
        (listId) =>
          checkboxes[account.id]?.[listId] && (
            <div
              className="flex-1 min-w-max"
              style={{ width: 300, maxWidth: 300, minWidth: 300 }}
            >
              <ListCard
                key={listId}
                list={listsMap.get(listId) || {}}
                account={account}
                search={search}
                upcomingFilter={upcomingFilter}
                  pastFilter={pastFilter}
                      />
                      </div>
                  )
            )}
          </div>
        </div>
  );
}

const Home = () => {
  const addAccount = useAccountsStore((state) => state.addAccount);
  const reset = useAccountsStore((state) => state.reset);
  const checkboxes = useUIStore((state) => state.checkboxes);
  const { data: accounts, isLoading: accountsLoading } = useAccounts();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const myMap = useTitleMap();
  const { theme, setTheme } = useTheme();
  const [search, setSearch] = useState("");
  const [upcomingFilter, setUpcomingFilter] = useState(false);
  const [pastFilter, setPastFilter] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const [view, setView] = useLocalStorage<View>("view", "grid");

  const listQueries = useTasklists(accounts);

  const listOrder = useUIStore((state) => state.listOrder) || {};

  const onClickAddAccount = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        const res = await axios.post(
          "/addAccount",
          { code: codeResponse.code },
          { withCredentials: true }
        );
        const user = res.data;
        addAccount(user);
        queryClient.invalidateQueries({ queryKey: ["accounts"] });
      } catch (error) {
        console.log("error", error);
      }
    },
    onError: (codeResponse) => console.log(codeResponse),
    flow: "auth-code",
    ux_mode: "popup",
    select_account: true,
    scope: ["https://www.googleapis.com/auth/tasks"],
  });

  const contentRef = useRef<HTMLDivElement>(null);

  const [hasBeenAskedForEmailConsent, setHasBeenAskedForEmailConsent] = useLocalStorage("hasBeenAskedForEmailConsent", false);
  const [showConsentModal, setShowConsentModal] = useState(false);
  const [consentToggle, setConsentToggle] = useState(true);

  useEffect(() => {
    if (!hasBeenAskedForEmailConsent) {
      const timer = setTimeout(() => {
        setShowConsentModal(true);
      }, 1500);

      return () => clearTimeout(timer);
    }
  }, [hasBeenAskedForEmailConsent]);

  const handleConsentSubmission = () => {
    try {
      const email = Object.values(accounts || {})[0]?.email;
      if (!email) {
        throw new Error("No email found");
      }
      saveConsent(email, consentToggle);
      setHasBeenAskedForEmailConsent(true);
    } catch (error) {
      console.error("Error in handleConsentSubmission:", error);
    } finally {
      setShowConsentModal(false);
    }
  };

  const scrollToEnd = () => {
    if (contentRef.current) {
      contentRef.current.scrollTo({
        left: contentRef.current.scrollWidth,
        behavior: 'smooth'
      });
    }
  };

  const scrollToStart = () => {
    if (contentRef.current) {
      contentRef.current.scrollTo({
        left: 0,
        behavior: 'smooth'
      });
    }
  };

  if (accountsLoading) return <LoadingPage />;
  if (!accounts || Object.keys(accounts).length === 0) return <Navigate to="/login" />;

  return (
    <div className="h-screen relative">
      <Button
        className={`rounded-full border-solid bg-card shadow-lg fixed top-[4rem] ${!showSidebar ? 'left-[1rem]' : 'left-[calc(20%+1rem)]'} z-10 transition-all duration-300`}
        size="icon"
        variant="ghost"
        onClick={() => setShowSidebar(!showSidebar)}
      >
        <ChevronsLeftIcon
          className={`h-5 w-5 text-muted-foreground dark:text-foreground hover:text-foreground ${!showSidebar ? 'rotate-180' : ''}`}
        />
      </Button>
      <div className="flex items-center justify-center bg-muted dark:bg-background border-b h-14">
        <div className="flex-1 flex items-center gap-2">
          <img className="h-4 ml-4" src={logo} alt="logo" />
          <h1
            className="text-md text-muted-foreground dark:text-foreground"
            style={{ fontFamily: "Open Sans" }}
          >
            Task Fusion
          </h1>
        </div>
        <div className="flex-1 flex justify-center items-center gap-2">
          <SearchIcon className="bg-muted" color="grey" />
          <Input
            type="search"
            value={search}
            id="search"
            onInput={(e) => setSearch(e.target.value)}
            placeholder="Search"
            className="w-[80%] border-none bg-card focus-visible:ring-0 focus-visible:outline-none focus-visible:ring-offset-0 shadow focus:shadow-md"
          />
        </div>
        <div className="flex-1 flex justify-end items-center mr-4">
          <Button className="rounded-full hover:bg-card hover:shadow" size="icon" variant="ghost" onClick={() => setView(view === "list" ? "grid" : "list")}>
            {view === "list" ? <Columns2Icon className="h-5 w-5 text-muted-foreground dark:text-foreground hover:text-foreground" /> : <Rows2Icon className="h-5 w-5 text-muted-foreground dark:text-foreground hover:text-foreground" />}
          </Button>
          <Popover>
            <PopoverTrigger>
              <Button className="rounded-full hover:bg-card hover:shadow" size="icon" variant="ghost">
                <Settings2Icon className="h-5 w-5 text-muted-foreground dark:text-foreground hover:text-foreground" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="dark:bg-card dark:border-border text-sm">
              <DropdownMenuLabel className="text-muted-foreground p-0">Settings</DropdownMenuLabel>
              <Separator className="px-4 my-2" />
              <div className="flex justify-between items-center">
                <span className="flex items-center gap-2">
                  upcoming tasks
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <InfoCircledIcon color="grey" className="" />
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Filter out upcoming tasks.</p>
                    </TooltipContent>
                  </Tooltip>
                </span>
                <Switch
                  className="dark:bg-secondary"
                  size="1"
                  checked={upcomingFilter}
                  onCheckedChange={(checked) => setUpcomingFilter(checked)}
                />
              </div>
              <div className="flex justify-between items-center mt-2">
                <span className="flex items-center gap-2">
                  past tasks
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <InfoCircledIcon color="grey" className="" />
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Filter out expired tasks.</p>
                    </TooltipContent>
                  </Tooltip>
                </span>
                <Switch
                  className="dark:bg-secondary"
                  size="1"
                  checked={pastFilter}
                  onCheckedChange={(checked) => setPastFilter(checked)}
                />
              </div>
            </PopoverContent>
          </Popover>

          <Button className="rounded-full hover:bg-card hover:shadow" size="icon" variant="ghost" onClick={() => setTheme(theme === "dark" ? "light" : "dark")}>
            {theme === "dark" ? <SunIcon className="h-5 w-5 text-foreground" /> : <MoonIcon className="h-5 w-5 text-muted-foreground hover:text-foreground" />}
          </Button>
        </div>
      </div>

      <div className="flex h-[calc(100%-3.5rem)]">
        {/* side bar */}
        <div className={`flex flex-col ${showSidebar ? "w-1/5" : "w-0"} transition-width duration-300 bg-muted dark:bg-background justify-between overflow-y-auto shadow-inner dark:border-r`}>
          <ScrollArea className="text-sm flex-1">
            {listQueries.map((query, i) =>
              query.isLoading ? (
                <span>loading account...</span>
              ) : (
                query.data && (
                  <AccountSiderList
                    key={i}
                    user={accounts[query.data?.[0]?.accountId]}
                    lists={query.data}
                    listTitleMap={myMap}
                  />
                )
              )
            )}
          </ScrollArea>
          <div className="w-full px-4">
            <Separator className="px-4" />
            <Button className="my-4 w-full hover:bg-card hover:shadow" onClick={onClickAddAccount} variant={"ghost"}>
              <PlusIcon className="h-4 w-4" />
              Add Account
            </Button>
          </div>
          <div className="flex flex-col p-4">
            <div className="my-4 mt-auto">
              <p className="text-muted-foreground text-xs">
                You are using a beta version of this product.
              </p>
              <p className="text-muted-foreground text-xs">
                Send feedback to{" "}
                <a className="underline" href="mailto: contact@task-fusion.pro">
                  contact@task-fusion.pro
                </a>
              </p>
            </div>

            <Button
              className="mt-4 flex gap-4"
              onClick={async () => {
                await logout();
                reset();
                queryClient.removeQueries();
                navigate("/login");
              }}
            >
              <LogOut className="w-4 h-4" />
              Logout
            </Button>
          </div>
        </div>

        {/* main content */}
        <div
          className={`${showSidebar ? "w-4/5" : "w-full"} transition-width duration-300 overflow-x-auto bg-muted dark:bg-background p-4 scrollbar-thin scrollbar-thumb-muted-foreground scrollbar-track-background relative`}
          ref={contentRef}
        >
          <div className="fixed right-4 top-[4rem] z-10">
          <Button 
            variant="ghost"
            size="icon"
            className="rounded-full hover:bg-card hover:shadow"
            onClick={scrollToStart}
          >
            <ArrowLeftIcon className="h-4 w-4" />
          </Button>
          <Button 
            variant="ghost" 
            size="icon"
            className="rounded-full hover:bg-card hover:shadow"
            onClick={scrollToEnd}
          >
            <ArrowRightIcon className="h-4 w-4" />
          </Button>
          </div>
          {Object.entries(accounts).map(([, account], i) => {
            const accountLists = queryClient.getQueryData(["tasklists", { userId: account.id }]);
            const order = listOrder[account.id] || [];
            const listsMap = new Map(accountLists?.map(list => [list.id, list]));
          
            return view === "list" ? 
              <ListView key={account.id} order={order} account={account} listsMap={listsMap} i={i} checkboxes={checkboxes} search={search} upcomingFilter={upcomingFilter} pastFilter={pastFilter} /> : 
              <GridView key={account.id} order={order} account={account} listsMap={listsMap} i={i} checkboxes={checkboxes} search={search} upcomingFilter={upcomingFilter} pastFilter={pastFilter} />;
          })}
        </div>
      </div>

      <Dialog open={showConsentModal} onOpenChange={setShowConsentModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Email Consent</DialogTitle>
            <DialogDescription>
            Hey there! We’d love to hear from you! Mind if we drop you the occasional email for feedback and product questions? We promise to keep it light!
            </DialogDescription>
          </DialogHeader>
          <div className="flex items-center space-x-2">
            <Switch
              id="consent-toggle"
              checked={consentToggle}
              onCheckedChange={setConsentToggle}
            />
            <Label htmlFor="consent-toggle">
              I consent
            </Label>
          </div>
          <DialogFooter>
            <Button onClick={handleConsentSubmission}>Submit</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Home;
