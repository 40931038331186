import { useState } from "react";
import { useCompleteTask, useDeleteTask } from "@/hooks";
import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button";
import { IAccount } from "@/types";
import { tasks_v1 } from "@googleapis/tasks";
import { Circle, Check, Trash, MoreVertical, ExternalLinkIcon } from 'lucide-react';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import MoveTaskDialog from "./MoveTaskDialog";

import "./TaskListItem.css"
import { truncateString } from "@/helpers";

interface ITasklistItemProps {
  task: tasks_v1.Schema$Task & {
    accountId: string;
  };
  list: string;
  account: IAccount;
  variant: "completed" | "needsAction";
  setEditing: (value: string) => void;
}

const convertToUTCString = (date: string) =>
  new Date(date).toUTCString().split(" ").slice(0,4).join(" ")

function linkifyUrls(text: string) {
  const urlPattern = /(?:(?:https?:\/\/)?(?:www\.)?(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?:\/[^\s)*<>"]*)?)/g;
  
  return text.replace(urlPattern, (url) => {
    const fullUrl = url.startsWith('http') ? url : `https://${url}`;
    return `<a href="${fullUrl}" target="_blank" rel="noopener noreferrer" class="text-blue-500 hover:underline">${url}</a>`;
  });
}

const TasklistItem = ({ task, list, account, variant, setEditing }: ITasklistItemProps) => {
  const completeTaskMutation = useCompleteTask(account, list);
  const deleteTaskMutation = useDeleteTask();
  const [moveTaskModalOpen, setMoveTaskModalOpen] = useState(false);

  const NeedsActionAction = () => (
    <div
      className="hover:cursor-pointer h-5 min-w-5 icon-container"
      onClick={() => {
        completeTaskMutation.mutate({
          task: task.id || "",
          updatedFields: { ...task, status: "completed" },
        });
      }}
    >
      <Check size="1.2rem" className="icon check-icon text-primary" />
      <Circle size="1.2rem" className="icon circle-icon text-muted-foreground" />
    </div>
  );
  const yesterdayDate = new Date();
  yesterdayDate.setDate(new Date().getDate() - 1);
  const isDue = yesterdayDate > new Date(task.due || "");

  const CompletedAction = () => (
    <div
      className="hover:cursor-pointer h-4 w-4 icon-container"
      onClick={() => {
        completeTaskMutation.mutate({
          task: task.id || "",
          updatedFields: { ...task, status: "needsAction" },
        });
      }}
    >
      <Check size="1.2rem"/>
    </div>
  );

  const TaskListItemInput = () => (
    <div className="w-full">
      <div
        onClick={(e) => { 
          if (!(e.target as HTMLElement).closest('a')) {
            setEditing(task.id || "");
          }
        }}
        className={`flex flex-col justify-start border-transparent w-full text-sm ${variant === "completed" && "line-through"} px-2`}
      >
        <p 
          className="break-words break-all max-h-16 overflow-hidden"
          dangerouslySetInnerHTML={{ __html: linkifyUrls(task.title || "") }}
        />
      </div>
    </div>
  );

  const handleDeleteTask = () => {
    deleteTaskMutation.mutate({
      account,
      tasklist: list,
      task: task.id || "",
    });
  };

  const DeleteButton = () => (
    <Button
      onClick={handleDeleteTask}
      className="h-8 w-10 p-0 delete-button"
      variant="ghost"
      size="sm"
    >
      <Trash className="h-4 w-4" />
    </Button>
  );

  const TaskActionsDropdown = () => (
    <DropdownMenu>
      <DropdownMenuTrigger className="flex flex-col items-center justify-center">
        <MoreVertical className="h-4 w-4 task-actions-dropdown translate-y-[0.1rem]" role="button"/>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem className="cursor-pointer" onClick={() => setMoveTaskModalOpen(true)}>
          Move Task
        </DropdownMenuItem>
        <DropdownMenuItem className="cursor-pointer text-red-500" onClick={handleDeleteTask}>
          Delete
        </DropdownMenuItem>
        {/* Add more menu items here as needed */}
      </DropdownMenuContent>
    </DropdownMenu>
  );

  return (
    <div
      className={`list-item-container active:bg-card active:shadow active:border py-1`}
    >
      <div className="flex items-start justify-between px-4 mb-1">
        {variant === "needsAction" ? <NeedsActionAction /> : <CompletedAction />}
        <TaskListItemInput />
        {variant !== "needsAction" ? <DeleteButton /> : <TaskActionsDropdown />}
      </div>
      <div className="mx-4 px-4"
        onClick={() => { setEditing(task.id || "")}}
      >
        {task.notes && <p className="text-xs leading-none text-muted-foreground mx-3 mb-2 break-words">{truncateString(task.notes, 50)}</p>}
        {(task.due || task.assignmentInfo) && 
        <div className="flex items-center flex-wrap">
          {task.due && <Badge className="ml-2 w-fit mb-2" variant={isDue ? "destructive" : "default"}>{convertToUTCString(task.due)}</Badge>}
          {task.assignmentInfo && (
            <a 
              href={task.assignmentInfo.linkToTask || ""}
              target="_blank" 
              rel="noopener noreferrer" 
              className="hover:cursor-pointer"
            >
              <Badge className="ml-1 w-fit mb-2" variant="outline">
                <ExternalLinkIcon className="h-4 w-4 mr-1" />Go to Task
              </Badge>
            </a>
          )}
        </div>
        }
      </div>
      <MoveTaskDialog open={moveTaskModalOpen} onOpenChange={setMoveTaskModalOpen} task={task} list={list} account={account} />
    </div>
  );
};

export default TasklistItem;