import { Outlet, Navigate, useLocation } from 'react-router-dom';
import * as Sentry from "@sentry/react";
import { useAccounts } from "../hooks"
import LoadingPage from '@/components/LoadingPage';

const PrivateRoute = () => {
  const { data: accounts, isLoading } = useAccounts();
  const location = useLocation();
  if (isLoading) return <LoadingPage />

  if (accounts) return <Outlet />
  Sentry.captureMessage("no accounts", {
    level: "info",
    extra: {
      accounts,
    },
  });
  return (
    <Navigate to="/login" state={{ from: location }} replace />
  )
}

export default PrivateRoute