import { Checkbox } from "@/components/ui/checkbox";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import useUIStore from "@/stores/useUIStore";
import { IAccount } from "@/types";
// import { useQueryClient } from "@tanstack/react-query";
import { tasks_v1 } from "@googleapis/tasks";
import { PlusIcon, UnplugIcon, GripVerticalIcon, CheckIcon, XIcon } from "lucide-react";
import { useInsertTasklist } from "@/hooks";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { useState, useEffect } from "react";
import BarLoader from "react-spinners/BarLoader";
import { useTheme } from "@/ThemeProvider";
import { getAccountDisplayName } from "@/helpers";
import RenameAccountDialog from "./RenameAccountDialog";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

interface SiderbarItemProps {
  title: string,
  checked: boolean,
  id: string,
  user: IAccount,
}

import "./AccountSiderList.css";
import DisconnectAccountDialog from "./DisconnectAccountDialog";
import { Tooltip, TooltipContent, TooltipTrigger } from "./ui/tooltip";

const SiderbarItem = ({ title, checked, id, user }: SiderbarItemProps) => {
  const checkboxes = useUIStore((state) => state.checkboxes);
  const setCheckbox = useUIStore((state) => state.setCheckbox);

  if (!checkboxes[user.id] || typeof checkboxes[user.id][id] !== "boolean") {
    setCheckbox(user.id, id, true);
  }
  return (
    <li key={id} className="flex items-center mb-1 px-3 rounded-xl cursor-pointer transition-colors hover:bg-card hover:shadow active:bg-card active:shadow gap-4 sidebar-item">
      <Checkbox
        checked={checked}
        defaultChecked={true}
        onCheckedChange={(c) => {
          setCheckbox(user.id, id, c as boolean)
        }}
        id={id}
      />
      <label className="cursor-pointer w-full" htmlFor={id}>{title}</label>
      <GripVerticalIcon className="h-6 w-6 text-muted-foreground p-0 m-0 opacity-25 transition-opacity grip-icon" />
    </li>
  );
};

interface AccountSiderListProps {
  user: IAccount,
  lists: (tasks_v1.Schema$TaskList & {accountId: string })[],
  listTitleMap: Record<string, string>
}

const AccountSiderList = ({ user, lists, listTitleMap }: AccountSiderListProps) => {
  const checkboxes = useUIStore((state) => state.checkboxes);
  const setCheckbox = useUIStore((state) => state.setCheckbox);
  const { theme } = useTheme();

  const insertTasklistMutation = useInsertTasklist(user)
  const [open, setOpen] = useState(false);
  const [openRename, setOpenRename] = useState(false);
  const [openDisconnect, setOpenDisconnect] = useState(false);
  const [title, setTitle] = useState("");
  const [checkAll, setCheckAll] = useState(true);
  // const queryClient = useQueryClient();

  const listOrder = useUIStore((state) => state.listOrder);
  const setListOrder = useUIStore((state) => state.setListOrder);
  const moveList = useUIStore((state) => state.moveList);

  // Initialize list order if not already set
  useEffect(() => {
    if (!listOrder[user.id]) {
      setListOrder(user.id, lists.map(list => list.id).filter((id): id is string => !!id));
    }
  }, [user.id, lists]);

  // const reconnect = async () => {
  //   await Promise.all([
  //     queryClient.invalidateQueries({ queryKey: ["accounts"] }),
  //     queryClient.invalidateQueries({ queryKey: ["tasklists"] }),
  //     queryClient.invalidateQueries({ queryKey: ["tasklist"] })
  //   ]);
  // }

  const onCheckAll = () => {
    lists.forEach(list => {
      if (list.id) setCheckbox(user.id, list.id, !checkAll);
    });
    setCheckAll(!checkAll);
  }

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const fromIndex = result.source.index;
    const toIndex = result.destination.index;

    moveList(user.id, fromIndex, toIndex);
  };

  return (
    <>
      <div className="flex items-center gap-4 pl-2 pt-4 account-name-sider-container mb-1">
        <Avatar className="h-6 w-6 mb-1 ml-2">
          <AvatarImage src={user.picture} />
          <AvatarFallback>{`${user?.name?.charAt(0)}`}</AvatarFallback>
        </Avatar>

        <Tooltip>
          <TooltipTrigger asChild>
            <span className="font-semibold flex-1 cursor-pointer" onClick={() => setOpenRename(true)}>{getAccountDisplayName(user, 18)}</span>
          </TooltipTrigger>
          <TooltipContent>
            Rename Account
          </TooltipContent>
        </Tooltip>
        {/* {mainUser?.id !== user.id && <Button size={"icon"} variant={"ghost"} onClick={reconnect}>
          <UpdateIcon className="h-4 w-4" />
        </Button>} */}
        <div className="flex mr-3">
          {/* <Button size={"icon"} variant={"ghost"} className="account-button rounded-full hover:bg-card hover:shadow" onClick={() => setOpenRename(true)}>
          <PencilIcon className="h-4 w-4 text-foreground" />
        </Button> */}
        {!user.primary && 
        <Tooltip>
          <TooltipTrigger>
            <Button size={"icon"} variant={"ghost"} className="account-button rounded-full hover:bg-card hover:shadow" onClick={() => setOpenDisconnect(true)}>
              <UnplugIcon className="h-4 w-4 text-foreground" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            Disconnect Account
          </TooltipContent>
        </Tooltip>}

        <Tooltip>
          <TooltipTrigger>
            <Button size={"icon"} variant={"ghost"} className="account-button rounded-full hover:bg-card hover:shadow" onClick={onCheckAll}>
              {checkAll ? <XIcon strokeWidth={2.5} className="h-4 w-4 text-foreground" /> : <CheckIcon strokeWidth={2.5} className="h-4 w-4 text-foreground" />}
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            {checkAll ? "Uncheck All" : "Check All"}
          </TooltipContent>
        </Tooltip>
        </div>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={`list-${user.id}`}>
          {(provided) => (
            <ul 
              className="mb-4 pl-2 pr-4"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {listOrder[user.id]?.map(
                (id, index) =>
                  id && (
                    <Draggable key={id} draggableId={id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <SiderbarItem
                            key={id}
                            id={id}
                            title={listTitleMap[id]}
                            checked={checkboxes?.[user.id]?.[id]}
                            user={user}
                          />
                        </div>
                      )}
                    </Draggable>
                  )
              )}
              {provided.placeholder}
              {/* {!insertTasklistMutation.isPending && <div className="w-full flex p-4"><PuffLoader size="1rem" loading={true} /></div>} */}
              {insertTasklistMutation.isPending && <div className="w-full flex justify-center p-4"><BarLoader width="100%" color={theme === "dark" ? "white" : "black"} loading={true} /></div>}
              <li
                // onClick={() => {
                //   console.log("creating list");
                //   insertTasklistMutation.mutate({ title: "New List", account: user });
                // }}
                className="flex items-center py-1 px-3 rounded-xl cursor-pointer transition-colors hover:bg-card hover:shadow gap-4"
              >
                <Dialog open={open} onOpenChange={setOpen}>
                  <DialogTrigger className="flex gap-4 items-center w-full">
                    <PlusIcon className="h-4 w-4" />
                    Create List
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>List Name</DialogTitle>
                    </DialogHeader>
                    <form
                      onSubmit={async (event) => {
                        event.preventDefault();
                        console.log("creating list");
                        insertTasklistMutation.mutate({ title, account: user });
                        setOpen(false)
                      }}
                    >
                      <Input required={true} className="mb-4" onChange={(e) => setTitle(e.target.value)} />
                      <div className="flex justify-end w-full gap-2">
                        <Button type="submit">
                          OK
                        </Button>
                      </div>
                    </form>
                  </DialogContent>
                </Dialog>
              </li>
            </ul>
          )}
        </Droppable>
      </DragDropContext>
      <RenameAccountDialog open={openRename} onOpenChange={setOpenRename} account={user} />
      <DisconnectAccountDialog open={openDisconnect} onOpenChange={setOpenDisconnect} account={user} />
    </>
  );
};

export default AccountSiderList;
